import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from "./index.module.less"
import useBigScreen from "@/hooks/bigScreen"
import { Divider } from "antd"
// import "antd/dist/reset.css"
import { CompareSelect } from "@/components/input/index"
import { Red_Button,RedTextButton } from "@/new_components/button/index"
import classNames from "classnames"
import Axios from "axios"
import { useIntl } from "react-intl"
import { buy_link, spec_price } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import useNavigate from '@/hooks/useNavigate'

const Index = () => {
  const intl = useIntl()
  let [json, setJson] = useState({})
  const countryCode = getCountry()
  const [compareList, setCompareList] = useState({})
  let ifBigscreen = useBigScreen(1080)
  // 所有设备options
  let [deviceList, setDeviceList] = useState([])
  let [forcheckedDevices, setForcheckedDevices] = useState([])
  // 当前比较的设备数组 从json获取并根据页面大小及勾选逻辑取前3或者2个
  const [checkedDevices, setCheckedDevices] = useState([])

  const intro={
    'XREAL One Pro':{
      learn_link:'/one-pro',
      buy_link:buy_link[countryCode]['onepro'].shop,
      price:buy_link[countryCode]['onepro'].price
    },
    'XREAL One':{
      learn_link:'/one',
      buy_link:buy_link[countryCode]['one'].shop,
      price:buy_link[countryCode]['one'].price
    },
    'XREAL Air 2 Pro':{
      learn_link:'/air2',
      buy_link:buy_link[countryCode]['air2pro'].shop,
      price:spec_price[countryCode]['air2pro'].price
    },
    'XREAL Air 2 Pro+XREAL Beam Pro':{
      learn_link:'',
      buy_link:buy_link[countryCode]['air2pro_beampro'].shop,
      price:buy_link[countryCode]['air2pro_beampro'].price
    },
    'XREAL Air 2':{
      learn_link:'/air2',
      buy_link:buy_link[countryCode]['air2'].shop,
      price:spec_price[countryCode]['air2'].price
    },
    'XREAL Air':{
      learn_link:'/air',
      buy_link:buy_link[countryCode]['air'].shop,
      price:spec_price[countryCode]['air'].price
    },
    'XREAL Air 2 Ultra':{
      learn_link:'/air2ultra',
      buy_link:buy_link[countryCode]['air2ultra'].shop,
      price:''
    },
  }

  useEffect(() => {
    function init() {
      const getJson = () => {
        Axios.get(`${process.env.resourceUrl}docs/compare/compare.json`).then(
          function(data) {
            setJson(data.data)
          }
        )
      }
      getJson()
    }
    init()
  }, [])

  useEffect(()=>{
    if(countryCode=='jp'||countryCode=='kr'){
        window.location.replace('/');
    }
},[])
  useEffect(() => {
    let obj = json[intl.locale] ? json[intl.locale] : json["en"]?json["en"]:{}
    setCompareList(obj)
    // json获取所有设备options
    const deviceList = Object.keys(obj)
   
    // 赋值
    setDeviceList(deviceList)
    // 取前3个作为默认数组 （供显示比较的设备）
    setForcheckedDevices(deviceList.slice(0, 3))

  }, [intl.locale, json])

  let changeDevice = (value, index) => {
    // 查看当前选择的设备是否在 forcheckedDevices 里
    let idx = forcheckedDevices.indexOf(value)
    let oldAry = [...forcheckedDevices]
    let newAry = [...forcheckedDevices]

    if (idx < 0) {
      // 不在 那么直接替换 并更新forcheckedDevices
      newAry[index] = value
      setForcheckedDevices(newAry)
    } else {
      // 在 需要交换 并更新forcheckedDevices
      newAry[index] = value
      newAry[idx] = oldAry[index]
      setForcheckedDevices(newAry)
    }
  }

  // forcheckedDevices 改变
  // bigscreen改变
  // 触发 checkedDevices （页面显示的内容）
  useEffect(() => {
    if (ifBigscreen) {
      setCheckedDevices(forcheckedDevices)
    } else {
      setCheckedDevices(forcheckedDevices.slice(0, 2))
    }
  }, [ifBigscreen, forcheckedDevices])

  // 筛选条件div sticky 的时候 出现shadow 否则隐藏shadow
  const [hasShadow, setHasShadow] = useState(false)
  useEffect(() => {
    // const handleScroll = () => {
    //   const stickyElement = document.querySelector(".sticky-element")
    //   const stickyTop = stickyElement.getBoundingClientRect().top
    //   // 判断元素是否到达顶部（top <= 0）
    //   if (stickyTop <= 0) {
    //     setHasShadow(true) // 添加阴影
    //   } else {
    //     setHasShadow(false) // 移除阴影
    //   }
    // }
    // window.addEventListener("scroll", handleScroll)
    // // 清理事件监听
    // return () => {
    //   window.removeEventListener("scroll", handleScroll)
    // }
  }, [])

  return (
    <>
      <Layout menuBlack={true} dontfixed={true}>
        {/* <SEO page="compare" /> */}
        {checkedDevices.length > 0 && (
          <>
            {/* hero */}
            <section className={classNames(style.max1440, style.hero)}>
              Which One is Right for You?
            </section>
            {/* compare */}
            <section className={style.comapre}>
              {/* sticky */}
              <div
                className={classNames(
                  "sticky-element",
                  style.sticky,
                  style.dropdown,
                  hasShadow ? style.hasShadow : ""
                )}
              >
                <div className={style.max1440}>
                  <div className={classNames(style.compareRow)}>
                    {checkedDevices.map((item, index) => {
                      return (
                        <CompareSelect
                          key = {index}
                          setCheckedValue={value => changeDevice(value, index)}
                          options={deviceList}
                          defaultValue={item}
                        ></CompareSelect>
                      )
                    })}
                  </div>
                </div>
              </div>
              {/* after sticky */}
              <div className={style.max1440}>
                {/* img */}
                <div className={classNames(style.compareRow)}>
                  {checkedDevices.map((item,index) => {
                    return (
                      <div className={style.compareColumn} key={index}>
                        <img
                          alt=""
                          style={{ width: "100%" }}
                          src={compareList[item].img}
                        />
                      </div>
                    )
                  })}
                </div>
                {/* name */}
                <div className={classNames(style.compareRow)}>
                  {checkedDevices.map((item,index) => {
                    return (
                      <div className={style.compareColumn} key={index}>
                        <div className={style.name}>
                          {compareList[item].name}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* price */}
                <div className={classNames(style.compareRow)}>
                  {checkedDevices.map((item,index) => {
                    return (
                      <div className={style.compareColumn} key={index}>
                        <div className={style.price}>
                          {intro[compareList[item].name].price}
                          {/* {compareList[item].price} */}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* link */}
                <div className={classNames(style.compareRow)}>
                  {checkedDevices.map((item,index) => (
                      <div className={style.compareColumn} key={`onepro_compare${index}`}>
                        {!!intro[compareList[item].name].buy_link&&<div className={style.buy}>
                          <Red_Button styles={{ lineHeight: "16px" }} onClick={()=>window.open(intro[compareList[item].name].buy_link)}>
                            BUY NOW
                          </Red_Button>
                        </div>}
                        
                        {!!intro[compareList[item].name].learn_link&& <div className={style.learn} onClick={()=>useNavigate(intro[compareList[item].name].learn_link)}>
                          <RedTextButton>LEARN MORE</RedTextButton>
                        </div>}
                       
                      </div>
                    )
                  )}
                </div>

                <Divider
                  style={{
                    background: "rgba(0, 0, 0, 0.20)",
                    margin: "60px 0",
                  }}
                />
                {/* features */}
                {compareList[checkedDevices[0]].features.map((item,deviceIndex) => {
                  return (
                    <div className={classNames(style.compareRow)} key={deviceIndex}>
                      {checkedDevices.map((item,index) => {
                        return (
                          <div
                            key={`checkDevicesSub${index}`}
                            className={style.compareColumn}
                            style={{ marginBottom: "60px" }}
                          >
                            {Object.entries(
                              compareList[item].features[deviceIndex]
                            ).map(([key, value]) => {
                              return (
                                <React.Fragment key={`feature-${index}-${value}`}>
                                  {key === "feature" && value !== "" && (
                                    <div className={style.feature}  dangerouslySetInnerHTML={{ __html: `${value}` }}></div>
                                  )}
                                  {key === "info" && value !== "" && (
                                    <div className={style.info}  
                                    dangerouslySetInnerHTML={{ __html: `${value}` }}></div>
                                  )}
                                  {key === "none" && value && (
                                    <div className={style.none}>-</div>
                                  )}
                                </React.Fragment>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  )
}

// export const Head = () => (
//   <SEO page="compare"/>
// )

export default Index
